
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { Category } from '@/interfaces/models/Category';
import { Permission } from '@/enums/Permission';
import adminHeaders from '@/components/category/adminHeaders';

@Component({
  components: { VTable },
})
export default class CategoryTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Category[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Number, default: () => 10 }) public limit!: number;

  get headers() {
    if (this.$isAdmin()) {
      return adminHeaders;
    }

    return headers;
  }

  public edit(item: Category) {
    if (!this.canChangeCoupon(item)) {
      return;
    }
    this.$router.push({ name: 'category.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public canToggleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.CATEGORY_VISIBLE);
    }
    return this.$can(Permission.CATEGORY_HIDE);
  }

  public canChangeCoupon(category: Category) {
    if (this.$isAdmin()) return true;
    if (category.name?.de === 'Coupons') {
      if (category.visible) {
        return this.$can(Permission.CATEGORY_COUPON_VISIBLE);
      }
      return this.$can(Permission.CATEGORY_COUPON_HIDE);
    }
    return true;
  }
}
