export default [
  {
    text: 'category.table.name',
    value: 'name',
  },
  {
    text: 'category.table.sort',
    value: 'sortOrder',
    editable: true,
  },
  {
    text: 'category.table.mainCategory',
    value: 'mainCategory',
  },
  {
    text: 'category.table.visible',
    value: 'visible',
    type: 'slot',
  },
];
