export default [
  {
    text: 'category.table.name',
    value: 'name',
  },
  {
    text: 'category.table.visible',
    value: 'visible',
    type: 'slot',
  },
];
