
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Category as CategoryModel } from '@/interfaces/models/Category';
import CategoryTable from '@/components/category/CategoryTable.vue';
import { Permission } from '@/enums/Permission';

const venue = namespace('venue');
const foodcard = namespace('foodcard');

@Component({
  components: { CategoryTable, VWrapper },
})
export default class Category extends Vue {
  @venue.State('active') public venue!: Venue;

  @foodcard.State((state) => state.categoryPagination.total) public total!: number;

  @foodcard.Action('fetchCategories') protected getCategories!: any;
  @foodcard.Action('makeCategoryVisible') protected makeCategoryVisible!: any;
  @foodcard.Action('hideCategory') protected hideCategory!: any;
  @foodcard.Action('destroyCategory') protected deleteCategory!: any;
  @foodcard.Action('setCategoryFilter') protected setFilter!: any;
  @foodcard.Action('setActiveCategory') protected setActive!: any;
  @foodcard.Action('updateCategory') protected updateCategory!: any;
  @foodcard.State('categories') protected items!: CategoryModel[];

  private limit: number = 20;

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public updateProp(data: { original: CategoryModel; field: string; value: string }) {
    const update: any = {};
    update[data.field] = data.value;
    this.updateCategory({ id: data.original._id, ...update });
  }

  public async destroy(cat: CategoryModel) {
    this.$startLoading('venue');
    await this.deleteCategory({ id: cat._id });
    this.$stopLoading('venue');
  }

  public async toggleVisibility({ item, value }: { item: CategoryModel; value: boolean }) {
    this.$startLoading('category');
    if (value) {
      this.hideCategory({ id: item._id });
    } else {
      this.makeCategoryVisible({ id: item._id });
    }
    this.$stopLoading('category');
  }

  public async mounted() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue && this.venue._id) {
      this.setFilter({ venue: this.venue._id, limit: this.limit });
      // this.$startLoading('category');
      // await this.getCategories({ page: 1 });
      // this.$stopLoading('category');
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id && this.$can(Permission.CATEGORY_VIEW)) {
      this.setFilter({ venue: this.venue._id, limit: this.limit });
      this.setActive(null);
      this.$startLoading('category');
      await this.getCategories({ page: 1 });
      this.$stopLoading('category');
    }
  }
}
